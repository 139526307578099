.App {
  text-align: center;
  background-color: "#FDFAF1";
 }
 
 .App-logo {
  height: 40vmin;
  pointer-events: none;
 }
 
 .App-logo {
  width: 25%; /* or any width you prefer */
  height: auto; /* This ensures the aspect ratio is maintained */
 }
 
 .App-header {
  position: fixed;
  background-color: #282c34;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
 }
 
 .App-link {
  color: #61dafb;
 }
 
 @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
 }
 
 /* Custom CSS */

 @font-face {
  font-family: 'Lato-Regular';
  src: url('./Lato/Lato-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
 }
 
 @font-face {
  font-family: 'Lato-Black';
  src: url('./Lato/Lato-Black.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
 }
 
 @font-face {
  font-family: 'Lato-Light';
  src: url('./Lato/Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
 }
 
 @font-face {
  font-family: 'Lato-Italic';
  src: url('./Lato/Lato-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
 }
 
 .MainImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  border-radius: 20px;
  border: 5px solid #F0EDE4;
 
 
 }
 
 .MainImageAndText {
  max-width: 55%;
  height: auto;
  display: flex;
 }
 
 
 @media (max-width: 1000px) {
  .MainImageAndText {
      max-width: 80%; /* 60% width on smaller screens */
  }
 }
 
 .NavBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10%;
  width: 100%;
  box-sizing: border-box;
  position: fixed; /* Fixes the navbar at the top */
  top: 0;
  left: 0;
  height: 80px;
  z-index: 1000; /* Ensures the navbar is above other content */
  background-color: #FDFAF1;
 }
 
 .App-logo {
  height: 100px;
  width: auto;
 }

 .main-content {
  margin-top: 40px; /* Adjust this value based on the height of your navbar */
  flex: 1;
  overflow: auto; /* Allow scrolling if the content overflows */
}

 .NavBarLinks {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
 }

 .NavBarLinks li {
    padding-right: 20px;
 }

 .NavBarLinks p {
  margin: 0;
  font-family: Arial, sans-serif;
  color: white;
  font-size: 15px;
 }
 
 .landing-page-content {
  height: 80vh; /* Full height of viewport */
  width: 100vw; /* Full width of viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FDFAF1; /* Example background color */
 }
 
 .slider-container {
  width: 100%;
  overflow: hidden;
  margin-top: 20px;
 }
 
 .slick-slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
 }
 
 .HeadingText {
  max-width: 100%; /* Maximum width for the text */
  margin-right: 20px; /* Space between text and image */
  font-family: 'Lato-Regular', sans-serif;
  color: #333; /* Adjust text color */
  font-size: 40px; /* Adjust font size */
  font-weight: bold;
 }
 
 
 .text-container {
  width: 100%;
  padding: 20px;
  text-align: left; /* Ensure text is left-aligned */
 }
 
 .appStoreImage {
  width: 150px; /* Ensures the image does not exceed its container's width */
  height: auto; /* Automatically adjusts height while maintaining aspect ratio */
  display: block; /* Ensures the image behaves like a block element */
 }
 
 
 .SupportingText {
  max-width: 100%; /* Maximum width for the text */
  margin-right: 20px; /* Space between text and image */
  font-family: 'Lato-Regular', sans-serif;
  color: #333; /* Adjust text color */
  font-size: 18px; /* Adjust font size */
 }
 
 
 .SupportingTextLink {
  display: inline-block;
  padding: 10px 20px; /* Adjust padding as needed */
  background-color: #3A604C; /* Button background color */
  color: white; /* Text color */
  text-decoration: none;
  border-radius: 5px; /* Rounded corners */
  border: 1px solid #3A604C; /* Button border */
  cursor: pointer; /* Show pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
 }
 
 .SupportingTextLink:hover {
  background-color: #31503f; /* Darker background color on hover */
 }
 
 
 @media (max-width: 850px) {
  .App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items to the top */
    height: 100vh; /* Full height of the viewport */
    overflow-x: hidden;
 }

  .NavBarContainer {
    height: 45px;
    padding: 5px 10px;
  }
 
  .NavBarLinks p {
    margin: 0;
    font-family: Arial, sans-serif;
    color: white;
    font-size: 16px;
    padding-bottom: 10px;
  }
  .landing-page-content {
    height: 80vh;
    width: 100vw;
    display: flex;

    align-items: flex-start;
    background-color: #FDFAF1;
 }

  .App-logo {
    height: 80px;
    width: auto;
    padding-bottom: 10px;
   }
 
  .MainImageAndText {
    flex-direction: column;
    align-items: center;
  }
 
  .MainImage {
    width: 100%;
    max-width: 197px;
    max-height: auto;
  }
 
  .text-container {
    text-align: center;
    padding: 5px;
  }
 
  .HeadingText {
    max-width: 100%; /* Maximum width for the text */
    margin-right: 20px; /* Space between text and image */
    margin-bottom: 0px;
    margin-top: 0px;
    font-family: 'Lato-Regular', sans-serif;
    color: #333; /* Adjust text color */
    font-size: 20px; /* Adjust font size */
  }

  .App-header {
    height: 60px;
  }
 
  .SupportingText {
    font-size: 14px;
    text-align: left;
    margin-right: 0px;
  }
 
  .appStoreImage {
    max-height: 100px;
    max-width: 100px;
    /* margin-left: auto; */
    position: absolute;
    bottom: 0;
    padding-right: 5px;
    padding-bottom: 0px;
    transform: translateX(100%);
  }
}
 
 /* Slider styles (MOVE) */
 
 
 /* .slider-container {
  padding-top: 200px
 }
 
 
 .slider-container {
  width: 80%;
  margin: 0 auto;
 }
 
 
 .slick-slide {
  text-align: center;
  height: 300px;
  display: flex !important;
  justify-content: center;
  align-items: center;
 }
 
 
 .slick-dots {
  bottom: -30px;
 }
 
 
 .slick-prev, .slick-next {
  z-index: 1;
 } */
 
 
 .slick-prev,
 .slick-next {
  font-size: 1.5rem;
  color: #000; /* Adjust color as needed */
  z-index: 1; /* Ensure arrows are above the slides */
 }
 
 
 a {
  text-decoration: none
 }
 .support-content {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 20px; /* Adjust this value as needed for vertical spacing */
 } 